import { template as template_607d89c62c9f45aabae700bff1208347 } from "@ember/template-compiler";
const WelcomeHeader = template_607d89c62c9f45aabae700bff1208347(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
