import { template as template_f53555cfc1bd4ee4bb6e16d8d93550c4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f53555cfc1bd4ee4bb6e16d8d93550c4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
