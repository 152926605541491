import { template as template_68a62044ccb14bd0987a1f6738da3ad0 } from "@ember/template-compiler";
const SidebarSectionMessage = template_68a62044ccb14bd0987a1f6738da3ad0(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
